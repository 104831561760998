<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >

    <b-modal
        id="modal-update"
        cancelTitle="Cancel"
        centered
        title="Update Game Service Offers"
        ok-title="Update"
        @ok.prevent="changeGameServiceStatus"
    >
      <b-row v-if="selectedGameServices">
        <b-col cols="12">
          <b-form-group>
            <b-form-checkbox
                v-model="selectedGameServices.isShowInHotSale"
            >
              Hot Sale
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <b-form-checkbox
                v-model="selectedGameServices.isShowInSpecialSale"
            >
              Special Sale
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <b-form-checkbox
                v-model="selectedGameServices.isShowInWeekSale"
            >
              Week Sale
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>

    <div v-if="gameServices">

      <!-- Table Container Card -->
      <b-card
          class="mb-0"
      >

        <h1 class="my-1">Manage Games Offers</h1>
        <b-row class="my-1">
          <b-col md="2">
            <b-form-group
                label="Count"
            >
              <v-select
                  v-model="perPage"
                  :clearable="false"
                  :options="perPageOptions"

              />

            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
                label="Search"
            >
              <Debouncer @setValue="setSearchQuery"></Debouncer>

            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
                label="Game"
            >
              <v-select
                  v-if="games"
                  v-model="gameId"
                  :options="games"
                  :reduce="name=> name.id"
                  label="name"

              />
            </b-form-group>
          </b-col>

        </b-row>

        <b-table
            ref="refUserListTable"
            :fields="myTableColumns"
            :items="gameServices"
            :sort-by.sync="sortBy"
            bordered

            class="position-relative"
            empty-text="Nothing found !"
            primary-key="id"
            responsive
            show-empty
            striped
        >


          <template #cell(operation)="data">
            <div class="d-flex align-items-center  w-100">
              <span v-b-modal.modal-update class="cursor-pointer mx-1" @click="setselectedGameService(data.item)">

              <feather-icon
                  class="text-primary"
                  icon="EditIcon"
                  size="20"
              />
              </span>
            </div>
          </template>
          <template #cell(isShowInHotSale)="data">

            <feather-icon
                v-if="!data.item.isShowInHotSale"
                class="text-danger"
                icon="XIcon"
                size="20"
            />
            <feather-icon
                v-else
                class="text-success"
                icon="CheckIcon"
                size="20"
            />
          </template>
          <template #cell(isShowInSpecialSale)="data">
            <feather-icon
                v-if="!data.item.isShowInSpecialSale"
                class="text-danger"
                icon="XIcon"
                size="20"
            />
            <feather-icon
                v-else
                class="text-success"
                icon="CheckIcon"
                size="20"
            />
          </template>
          <template #cell(isShowInWeekSale)="data">
            <feather-icon
                v-if="!data.item.isShowInWeekSale"
                class="text-danger"
                icon="XIcon"
                size="20"
            />
            <feather-icon
                v-else
                class="text-success"
                icon="CheckIcon"
                size="20"
            />
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
                class="d-flex align-items-center justify-content-center"
                cols="12"
                sm="12"
            >
              <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
            </b-col>
            <!-- Pagination -->
            <b-col
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                cols="12"
                sm="6"
            >
              <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  :total-rows="totalCount"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>

import { BCard, BCol, BFormCheckbox, BFormGroup, BModal, BOverlay, BPagination, BRow, BTable } from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import Helper from '@/libs/Helper'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import { GetGameServicesByGameServiceCategoryId, UpdateGameServiceOffers } from '@/libs/Api/gameService'
import Filters from '@/views/components/Filters.vue'
import Debouncer from '@/views/components/debouncer.vue'
import { GetAllGames } from '@/libs/Api/games'

export default {
  title: 'manage-offers',
  name: 'manage-offers',
  data() {
    return {
      required,
      totalCount: null,
      showOverlay: false,
      currentPage: 1,
      search: '',
      gameServices: {
        name: '',
      },
      gameId: '',
      baseURL: Helper.baseUrl + 'media/gallery/profile/',
      perPage: 10,
      sortBy: '',
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {
          key: 'gameServiceId',
          label: '#',
          sortable: true
        },
        {
          key: 'title',
          label: 'name',
          sortable: true
        },
        {
          key: 'isShowInHotSale',
          label: 'Hot Sale'
        },
        {
          key: 'isShowInSpecialSale',
          label: 'Special Sale'
        },
        {
          key: 'isShowInWeekSale',
          label: 'Week Sale'
        },
        {
          key: 'operation',
          label: 'operation'
        },
      ],
      games: null,
      pageNumber: 1,
      selectedGameServices: null,
    }
  },
  async created() {
    await Promise.all([
      this.getGameServices(),
      this.getGames()
    ])
  },
  watch: {
    currentPage: function () {
      this.getGameServices()
    },
    perPage: function (val) {
      this.getGameServices()
    },
    search: function () {
      this.getGameServices()
    },
    gameId: function () {
      this.getGameServices()
    }

  },
  methods: {
    setSearchQuery(query) {
      this.search = query
    },
    async getGames() {
      let _this = this
      _this.showOverlay = true
      let getAllGames = new GetAllGames(_this)
      let data = {
        pageNumber: 1,
        count: 100,
        search: ''
      }
      getAllGames.setParams(data)
      await getAllGames.fetch(function (content) {

        _this.games = content.data.data
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    setselectedGameService(item) {
      this.selectedGameServices = JSON.parse(JSON.stringify(item))
    },
    async getGameServices() {
      let _this = this
      _this.showOverlay = true

      let getGameServicesByGameServiceCategoryId = new GetGameServicesByGameServiceCategoryId(_this)
      getGameServicesByGameServiceCategoryId.setQuery({
        search: _this.search,
        pageNumber: _this.currentPage,
        count: _this.perPage,
        gameId: _this.gameId ? _this.gameId : ''
      })
      await getGameServicesByGameServiceCategoryId.fetch(function (content) {
        _this.showOverlay = false
        _this.gameServices = content.data.data
        _this.totalCount = content.data.totalCount
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })

    },
    async changeGameServiceStatus() {
      let _this = this
      _this.showOverlay = true
      let updateGameServiceOffers = new UpdateGameServiceOffers(_this)
      updateGameServiceOffers.setParams({
        isShowInSpecialSale: _this.selectedGameServices.isShowInSpecialSale,
        isShowInWeekSale: _this.selectedGameServices.isShowInWeekSale,
        isShowInHotSale: _this.selectedGameServices.isShowInHotSale,
      })
      updateGameServiceOffers.setQuery({
        gameServiceId: _this.selectedGameServices.gameServiceId
      })
      await updateGameServiceOffers.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          props: {
            title: `success`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `Game Service offers successfully updated`,
          },
        })
        _this.getGameServices()
        _this.selectedGameServices = null
        _this.$bvModal.hide('modal-update')
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })

    },

  },

  components: {
    Debouncer,
    Filters,
    BCard,
    BRow,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BTable,
    BPagination,
    BOverlay,
    BModal,
    vSelect,
  },
}
</script>

<style scoped>

</style>
