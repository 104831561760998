<template>
  <b-row>
    <b-col cols="12">
      <b-row>
        <b-col cols="3">
          <b-form-group
              label="Search"
          >
            <b-form-input
                v-model="filters.search"
            />
          </b-form-group>
        </b-col>
        <b-col
               cols="3"
        >
          <b-form-group
              label="Count"
          >
            <v-select
                v-model="filters.perPage"
                :options="perPageOptions"
                class="per-page-selector w-75 mx-50"
            />
          </b-form-group>

        </b-col>

      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { BCol, BFormGroup, BFormInput, BRow } from 'bootstrap-vue'
import vSelect from 'vue-select'
export default {
  name: 'Filters',
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormGroup,vSelect

  },
  data() {
    return {
      filters: {
        search: '',
        perPage:10
      },
      perPageOptions: [10, 20, 30, 40, 50],

    }
  }
}
</script>

<style scoped>

</style>
